import { ImageOverlay, MapContainer } from "react-leaflet";
import L from 'leaflet';

// everything will look wrong if this import is missing
import 'leaflet/dist/leaflet.css';

import StationMarkerGroup from "./StationMarkerGroup";

interface Props {
    imgUrl: string;
    imgWidth: number;
    imgHeight: number;
    viewWidth: number;
    viewHeight: number;
}

export default function EventMapInner(props: Props) {
    const { imgUrl, imgWidth, imgHeight, viewWidth, viewHeight } = props;

    // Calculate the largest we can zoom the image
    // while the whole thing fits in the screen.
    const magnification = Math.min(
        viewWidth / imgWidth,
        viewHeight / imgHeight
    );

    // final dimensions of displayed image
    const overlayWidth = magnification * imgWidth;
    const overlayHeight = magnification * imgHeight;

    const bounds = L.latLngBounds([0, 0], [overlayHeight, overlayWidth]);
    return <MapContainer
        style={{ width: "100%", height: "100%" }}
        scrollWheelZoom={true}
        attributionControl={false}
        crs={L.CRS.Simple}
        bounds={bounds}
        minZoom={0}
        maxZoom={5}
    >
        <ImageOverlay
            url={imgUrl}
            bounds={bounds}
        />
        <StationMarkerGroup bounds={bounds} magnification={magnification}/>
    </MapContainer>
}
import { RouterProvider, createBrowserRouter, redirect, useNavigate } from "react-router-dom"
import LoginScreen from "./screens/LoginScreen"
import { db } from "./instant";
import { CREATE_EVENT_ROUTE, DEFAULT_ROUTE, EVENTS_LIST_ROUTE, IMAGE_UPLPOAD_RETRY_EVENT_TYPE, LOGIN_ROUTE } from "./constants";
import ErrorScreen from "./screens/ErrorScreen";
import CreateEventScreen from "./screens/CreateEventScreen";
import EventsListScreen from "./screens/EventsListScreen";
import EventScreen from "./screens/EventScreen";
import { useEffect } from "react";
import { Workbox } from 'workbox-window';
import EditMapListScreen from "./screens/EditMapListScreen";

interface RouteProps {
  children: JSX.Element,
}

function PrivateRoute({ children }: RouteProps) {
  const { isLoading, user, error } = db.useAuth()
  const navigate = useNavigate();

  // TODO: This flashes before the page loads, kind of annoying
  if (isLoading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Uh oh! {error.message}</div>
  }

  if (user) {
    return <>
      {children}
    </>;
  }

  // If not loading, errored, or logged in, redirect to the login screen.
  navigate(LOGIN_ROUTE, { replace: true });
  return <></>;
}

function privateRoute(children: JSX.Element) {
  return <PrivateRoute>{children}</PrivateRoute>;
}

export const router = createBrowserRouter([
  {
    path: "/",
    loader: () => redirect(DEFAULT_ROUTE),
    errorElement: <ErrorScreen />
  },
  {
    path: LOGIN_ROUTE,
    element: <LoginScreen />
  },
  {
    path: CREATE_EVENT_ROUTE,
    element: privateRoute(<CreateEventScreen />)
  },
  {
    path: EVENTS_LIST_ROUTE,
    element: privateRoute(<EventsListScreen />)
  },

  {
    path: "/event/:eventId/map",
    element: privateRoute(<EventScreen />)
  },
  {
    path: "/event/:eventId/inventory",
    element: privateRoute(<EventScreen />)
  },
  {
    path: "/event/:eventId/notes",
    element: privateRoute(<EventScreen />)
  },
  {
    path: "/event/:eventId/maplist",
    element: privateRoute(<EditMapListScreen />)
  },
  {
    path: "/event/:eventId",
    loader: (args) => {
      const { eventId } = args.params
      return redirect(`/event/${eventId}/map`);
    },
  },
  {
    path: "/event/:eventId/*",
    loader: (args) => {
      const { eventId } = args.params
      return redirect(`/event/${eventId}/map`);
    },
  },

  {
    path: "/viewevent/:eventId/map",
    element: <EventScreen viewOnly />
  },
  {
    path: "/viewevent/:eventId/inventory",
    element: <EventScreen viewOnly />
  },
  {
    path: "/viewevent/:eventId/notes",
    element: <EventScreen viewOnly />
  },
  {
    path: "/viewevent/:eventId",
    loader: (args) => {
      const { eventId } = args.params
      return redirect(`/viewevent/${eventId}/map`);
    },
  },
  {
    path: "/viewevent/:eventId/*",
    loader: (args) => {
      const { eventId } = args.params
      return redirect(`/viewevent/${eventId}/map`);
    },
  },
]);

export default function App() {
  useEffect(() => {
    // Retry image uploads when back online
    // see https://stackoverflow.com/a/55338293/4228052
    if ('serviceWorker' in navigator) {
      const wb = new Workbox('/service-worker.js')

      window.addEventListener('online', function (event) {
        console.log('back online!');
        wb.messageSW({ type: IMAGE_UPLPOAD_RETRY_EVENT_TYPE });
      });
    }

  }, [])
  return <RouterProvider router={router} />;
}

import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { db } from '../instant';
import { Button, Paper, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import LoadingIndicator from './LoadingIndicator';
import { CreateMap, bgImageKey, createMaps } from '../upload';
import AddNewMaps from './AddNewMaps';
import { useEvent } from '../context';
import MapList from './MapList';

// FieldArray info:
// - https://deaconn.net/blog/view/react-form-with-dynamic-fields-and-formik
// - https://formik.org/docs/api/fieldarray

interface Values {
    maps: CreateMap[]
}

interface Props {
    eventId: string | undefined
}

export default function EditMapListForm(props: Props) {
    const navigate = useNavigate();
    const { user, isLoading, error } = db.useAuth();
    const [isUploading, setIsUploading] = useState(false);
    const { eventId } = props;
    const event = useEvent(eventId);
    const eventMaps = event?.maps || [];
    const numOtherMaps = eventMaps.length;

    if (isLoading) {
        return <div>Authenticating...</div>
    }
    if (error) {
        return <div>Error authenticating: {error.message}</div>
    }

    if (!event) {
        return <p>ERROR: loading event data</p>
    }

    const initialValues: Values = {
        maps: [],
    }

    const maybeUploadSpinner = isUploading ? <LoadingIndicator message="Updating event..." /> : <></>;

    return <Paper sx={{ position: "relative", maxWidth: "400px", margin: "auto", padding: 2 }} elevation={4}>
        <Typography variant="h4" sx={{ textAlign: "center" }}>Edit map list</Typography>
        <br />
        <Typography variant="h4" sx={{ textAlign: "center" }}>{event.name}</Typography>
        <Formik
            initialValues={initialValues}
            validate={values => {
                const errors: any = {};

                // Check background images
                values.maps.forEach((map, index) => {
                    const key = bgImageKey(index);
                    if (!map.bgImage) {
                        errors[key] = 'Required';
                    }
                });

                return errors;
            }}

            onSubmit={async (values, { setSubmitting }) => {
                console.log('submit values', values);

                const token = user?.refresh_token;
                if (!token) {
                    throw new Error('no token!');
                }

                const params = {
                    maps: values.maps,
                    eventId: event.id,
                    orderOffset: numOtherMaps,
                    otherTxns: [],
                    token,
                    setIsUploading,
                    setSubmitting,
                    navigate,
                };

                await createMaps(params);
            }}
        >
            {({ values, isSubmitting }) => (
                <Form>
                    <Stack direction="column" spacing={1}>
                        <MapList event={event} />

                        <AddNewMaps
                            maps={values.maps}
                            isSubmitting={isSubmitting}
                            numOtherMaps={numOtherMaps}
                        />
                        <br />

                        <Button
                            sx={{
                                position: "absolute",
                                bottom: "16px",
                                right: "16px"
                            }}
                            type="submit"
                            disabled={isSubmitting}
                        >
                            Save Changes
                        </Button>
                    </Stack>
                </Form>
            )}
        </Formik>
        {maybeUploadSpinner}
    </Paper >
}
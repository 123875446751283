import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { EventMap } from "../schema";
import { db } from "../instant";
import { tx } from "@instantdb/react";

interface Props {
    eventName: string,
    map?: EventMap,
    open: boolean,
    onClose: () => void,
}

export default function DeleteMapDialog(props: Props) {
    const { user } = db.useAuth();

    if (props.map === null) {
        return <></>
    }

    const deleteAndClose = async () => {
        const token = user?.refresh_token;
        let headers: HeadersInit = {};
        if (token) {
            headers = { token };
        }

        if (props.map) {
            const pins = props.map.mapPins || []

            // TODO: Make sure image is not
            // used elsewhere before deleting

            // Delete image
            await fetch(
                props.map.bgImageUrl,
                {
                    method: "DELETE",
                    headers,
                }
            );

            // Delete map pins for this event
            const deletePins = pins.map(
                pin => tx.mapPins[pin.id].delete()
            );

            await db.transact([
                tx.maps[props.map.id].delete(),
                ...deletePins,
            ]);

            props.onClose();
        }
    }

    const eventName = props.eventName || "<UNDEFINED>"
    const mapName = props.map?.name || "<UNDEFINED>";

    return <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>
            Really delete this map?
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                The map "{mapName}" of event "{eventName}" will be deleted.
                This action cannot be undone.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onClose} autoFocus>Cancel</Button>
            <Button disabled={!user} onClick={deleteAndClose} color="error">Delete</Button>
        </DialogActions>
    </Dialog>
}
import { FieldArray } from "formik";
import CreateMapFormSection from "./CreateMapFormSection";
import { Box, Button } from "@mui/material";
import { CreateMap, newEmptyMap } from "../upload";

interface Props {
    maps: CreateMap[]
    isSubmitting: boolean
    numOtherMaps?: number
}

export default function AddNewMaps(props: Props) {
    const { maps, isSubmitting } = props;
    const numOtherMaps = props.numOtherMaps || 0;
    return <FieldArray name="maps">
        {({ push, remove }) => (
            <>
                {maps.map((map, index) => (
                    <CreateMapFormSection
                        key={map.id}
                        map={map}
                        index={index}
                        deleteable={maps.length + numOtherMaps > 1}
                        remove={remove}
                    />
                ))}

                <Box sx={{ height: "64px" }} />

                <Button
                    sx={{
                        position: "absolute",
                        bottom: "64px",
                        right: "16px"
                    }}
                    color="success"
                    disabled={isSubmitting}
                    onClick={() => push(newEmptyMap())}
                >
                    Add map
                </Button>
            </>
        )}
    </FieldArray>
}
import { useEffect, useState } from "react";
import { db } from "../instant";
import { fetchImage, timeout } from "../utils";
import { isLocalhost } from "../serviceWorkerRegistration";
import { useEventsList } from "../context";
import DownloadNotifier, { DownloadStatus } from "./DownloadNotifier";

// Check whether an image is present in the cache
const isCached = async (imageUrl: string): Promise<boolean> => {
    const cache = await window.caches.open('images');
    const response = await cache.match(imageUrl);
    const isPresent = (response !== undefined);
    return isPresent;
}

export default function CacheUpdater() {
    const events = useEventsList();
    const { user } = db.useAuth();
    const token = user?.refresh_token;

    // Whether to show the loading indicator
    const [loadingStatus, setLoadingStatus] = useState<DownloadStatus | null>(null);

    // Query station types to cache icon info
    db.useQuery({ stationTypes: {} })

    const maybeDownloadOneImage = async (url: string, token: string) => {
        const alreadyCached = await isCached(url);
        if (!alreadyCached) {
            console.log("downloading image ", url);
            await fetchImage(url);
            console.log("finished downloading image ", url);
        }
    }

    const imageUrls = events.map(event => event.bgImageUrl);
    imageUrls.sort();

    // Lists aren't good for useEffect dependencies
    // see https://stackoverflow.com/q/59467758/4228052
    const imageUrlsString = JSON.stringify(imageUrls);

    // Preload all event background images for offline use
    // fetching an image automatically causes
    // the service worker to cache it.
    useEffect(() => {
        // This is silly but works
        // see https://stackoverflow.com/a/77086953/4228052
        const imageUrls: string[] = JSON.parse(imageUrlsString);
        if (imageUrls && token && !isLocalhost) {
            const fetchAllImages = async () => {
                // Only show the loading screen if downloads
                // last at least 2 seconds (cache hits should be quick)
                const timer = timeout(2000).then(() => "TIME");
                const downloadPromises = imageUrls.map(url => maybeDownloadOneImage(url, token));
                const allDownloadsPromise = Promise.all(downloadPromises);

                // Which promise resolves first?
                const first = await Promise.race([timer, allDownloadsPromise]);
                if (first === "TIME") {
                    // The images are still loading,
                    // so assume they're being downloaded
                    // from the network. Show loading screen.
                    setLoadingStatus('Downloading')

                    // once they're done, hide the loading screen.
                    await allDownloadsPromise;
                    setLoadingStatus('Finished');

                    // Auto-close notification after a few seconds
                    await timeout(2000);
                    setLoadingStatus(null);
                }
            }

            fetchAllImages().catch(console.error);
        }
    }, [imageUrlsString, token]);

    return <DownloadNotifier status={loadingStatus} />;
}
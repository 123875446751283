import { Button } from "@mui/material";
import { Link} from "react-router-dom";
import { LOGIN_ROUTE } from "../constants";

export default function LoginButton() {
    const currentPath = window.location?.pathname;
    const searchParams = currentPath ? `?redirect=${currentPath}` : '';
    const dest = `${LOGIN_ROUTE}${searchParams}`;
    return <Link to={dest}>
        <Button>
            Login
        </Button>
    </Link>
}

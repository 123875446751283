import { Event } from "../schema";
import MapListSection from "./MapListSection";

interface Props {
    event: Event
}

export default function MapList(props: Props) {
    const maps = props.event.maps || [];
    return <>
        {maps.map(map => (
            <MapListSection
                key={map.id}
                eventName={props.event.name}
                map={map}
                deleteable={maps.length > 1}
            />
        ))}
    </>;
}

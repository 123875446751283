import { Box, Pagination, Paper } from "@mui/material";

interface Props {
    numPages: number
    currentPage: number
    setPage: (page: number) => void
}

export default function MapPagination(props: Props) {
    return <Box sx={{
        position: "absolute",
        bottom: "20px",
        width: "100%",
        display: "flex",
        zIndex: 500,
    }}>
        <Paper
            elevation={2}
            sx={{
                borderRadius: 5,
                marginLeft: "auto",
                marginRight: "auto",
            }}
        >
            <Pagination
                sx={{
                    margin: 1,
                }}
                siblingCount={0}
                count={props.numPages}
                // page numbers start at 1.
                // Use +1 / -1 to adjust for JS zero-indexing.
                page={props.currentPage + 1}
                onChange={(_, page) => props.setPage(page - 1)}
            />
        </Paper>
    </Box>
}
import InfoIcon from "@mui/icons-material/Info";
import { Button } from "@mui/material";
import { useState } from "react";
import AppInfoDialog from "./AppInfoDialog";

export default function AppInfoButton() {
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleClick = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    }

    return <>
        <AppInfoDialog open={dialogOpen} onClose={handleCloseDialog} />
        <Button onClick={handleClick}>
            <InfoIcon />
        </Button>
    </>
}
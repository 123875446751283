import { Dialog, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";

interface Props {
    open: boolean,
    onClose: () => void,
}

const GIT_COMMIT = process.env.REACT_APP_GIT_COMMIT;
const RELEASE_DATE = process.env.REACT_APP_RELEASE_DATE;

export default function AppInfoDialog(props: Props) {
    return <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>
            <Typography variant="h4" sx={{ textAlign: "center" }}>
                App Info
            </Typography>
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                Release date: {RELEASE_DATE}
            </DialogContentText>
            <DialogContentText>
                Git commit: #{GIT_COMMIT}
            </DialogContentText>
            <br />
            <DialogContentText>
                If you aren't seeing the latest version of the app, make sure that this app is not open in another browser tab. That would prevent it from updating.
            </DialogContentText>
        </DialogContent>
    </Dialog>
}
import { AppBar, Box, Toolbar } from "@mui/material";
import HomeButton from "./HomeButton";
import LogoutButton from "./LogoutButton";
import AppInfoButton from "./AppInfoButton";
import DevIndicator from "./DevIndicator";
import { useContext } from "react";
import { ViewOnlyContext } from "../context";
import ViewOnlyIndicator from "./ViewOnlyIndicator";
import { db } from "../instant";
import LoginButton from "./LoginButton";

export default function Navbar() {
    const { isLoading, user } = db.useAuth()

    const authButton = isLoading
        ? <></>
        : user
            ? <LogoutButton />
            : <LoginButton />

    const isDev = (process.env.REACT_APP_INSTANTDB_ENV === 'development');
    const isViewOnly = useContext(ViewOnlyContext);
    const maybeDevIndicator = isDev ? <DevIndicator /> : <></>;
    const maybeViewOnlyIndicator = isViewOnly ? <ViewOnlyIndicator /> : <></>;
    return <>
        <AppBar position="fixed" sx={{ bgcolor: "white" }} elevation={1} >
            <Toolbar variant="dense">
                <HomeButton />
                <Box sx={{ flexGrow: 1 }} />
                {maybeDevIndicator}
                {maybeViewOnlyIndicator}
                <AppInfoButton />
                {authButton}
            </Toolbar>
        </AppBar>
        <Toolbar variant="dense" sx={{ padding: 1, elevation: 0 }} />
    </>
}
import { Container, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { MapPin, StationType } from "../schema"
import { getStationIconSrc, getStationType } from "../utils";
import { useEvent } from "../context";

interface StationCount {
    station: StationType,
    count: number,
}

interface BinCounts {
    black: number,
    blue: number,
    green: number,
    slimJim: number,
    compostBucket: number,
    all: number,

}

function countStations(mapPins: MapPin[]): StationCount[] {
    const records: Record<string, StationCount> = {};

    for (let pin of mapPins) {
        const stationType = getStationType(pin);
        if (stationType) {
            const id = stationType.id;
            if (id in records) {
                records[id].count += 1;
            } else {
                records[id] = { station: stationType, count: 1 };
            }
        }
    }

    const counts = Object.values(records);
    counts.sort((a, b) => a.station.order - b.station.order);
    return counts;
}

function countBins(stationCounts: StationCount[]): BinCounts {
    let black = 0;
    let blue = 0;
    let green = 0;
    let slimJim = 0;
    let compostBucket = 0;

    for (let { station, count } of stationCounts) {
        black += count * station.black;
        blue += count * station.blue;
        green += count * station.green;
        slimJim += count * station.slimJim;
        compostBucket += count * station.compostBucket;
    }

    const all = black + blue + green + slimJim + compostBucket;

    const binCounts: BinCounts = {
        black,
        blue,
        green,
        slimJim,
        compostBucket,
        all
    }

    return binCounts;
}

interface Props {
    eventId: string;
}

export default function EventInventory(props: Props) {
    const event = useEvent(props.eventId);
    const mapPins = event?.maps?.flatMap(map => map.mapPins || []) || [];

    const stationCounts = countStations(mapPins);
    const binCounts = countBins(stationCounts);

    return <Container maxWidth="sm" sx={{ paddingBottom: "30px" }}>
        <br />
        <Typography variant="h4" sx={{ textAlign: "center" }}>Stations</Typography>
        <Paper sx={{ overflowX: "auto" }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Icon</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Count</TableCell>
                        <TableCell>Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {stationCounts.map(({ station, count }, key) =>
                        <TableRow key={key}>
                            <TableCell><img
                                width="50px"
                                height="50px"
                                alt={station.name}
                                src={getStationIconSrc(station)}
                            /></TableCell>
                            <TableCell>{station.name}</TableCell>
                            <TableCell>{count}</TableCell>
                            <TableCell>{station.description}</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Paper>

        <br />
        <Typography variant="h4" sx={{ textAlign: "center" }}>Bin Totals</Typography>
        <Paper sx={{ overflowX: "auto" }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Black</TableCell>
                        <TableCell>Blue</TableCell>
                        <TableCell>Green</TableCell>
                        <TableCell>Slim Jim</TableCell>
                        <TableCell>Compost Bucket</TableCell>
                        <TableCell>All</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>{binCounts.black}</TableCell>
                        <TableCell>{binCounts.blue}</TableCell>
                        <TableCell>{binCounts.green}</TableCell>
                        <TableCell>{binCounts.slimJim}</TableCell>
                        <TableCell>{binCounts.compostBucket}</TableCell>
                        <TableCell>{binCounts.all}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Paper>
    </Container>
}
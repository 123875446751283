import { Button, Stack, Typography } from "@mui/material";
import { ChangeEvent, MouseEventHandler, useContext, useState } from "react"
import { ViewOnlyContext } from "../context";

interface Props {
    value: string
    update: (value: string) => void
}

export default function NotesWidget(props: Props) {
    const [editedValue, setEditedValue] = useState(props.value);
    const viewOnly = useContext(ViewOnlyContext);
    const [editing, setEditing] = useState(false);
    const { value, update } = props;

    const onChange = (ev: ChangeEvent<HTMLTextAreaElement>) => {
        setEditedValue(ev.target.value);
    }

    const editArea = <textarea
        key="editArea"
        style={{
            width: "100%",
            height: "80%",
            minHeight: "100px",
        }}
        value={editedValue}
        onChange={onChange}
        onClick={(ev) => {
            // Prevent click from somehow switching
            // to the map screen.
            ev.stopPropagation();
        }}
    />;
    const viewArea = <Typography
        key="viewArea"
        align="left"
        whiteSpace="pre-wrap"
    >
        {value}
    </Typography>

    const onSave: MouseEventHandler<HTMLButtonElement> = ev => {
        // No editing in view-only mode
        if (viewOnly) { return; }

        update(editedValue);
        setEditing(false);

        // Prevent button click from closing popup
        ev.stopPropagation();
    }

    const onEdit: MouseEventHandler<HTMLButtonElement> = ev => {
        // No editing in view-only mode
        if (viewOnly) { return; }

        setEditedValue(value);
        setEditing(true);

        // Prevent button click from closing popup
        ev.stopPropagation();
    }

    const buttonWidth = "150px";

    const editButton = <Button
        key="editButton"
        onClick={onEdit}
        variant="contained"
        sx={{ width: buttonWidth }}
    >
        Edit Notes
    </Button>

    const saveButton = <Button
        key="saveButton"
        onClick={onSave}
        variant="contained"
        sx={{ width: buttonWidth }}
    >
        Save Changes
    </Button>

    const viewOnlyWidget = viewArea;
    const editableWidget = editing
        ? [editArea, saveButton]
        : [viewArea, editButton];

    return <Stack
        direction="column"
        spacing={2}
        sx={{
            width: "100%",
            height: "100%",
            alignItems: "center",
        }}
    >
        {viewOnly ? viewOnlyWidget : editableWidget}
    </Stack>
}
import { Stack } from "@mui/material"
import { StationType } from "../schema"
import MapIconOption from "./MapIconOption"

interface Props {
    stations: StationType[]
    currentStation: StationType | null
    setCurrentStation: (station: StationType | null) => void
}

export default function StationTypeSelector(props: Props) {
    return <Stack direction="row" sx={{overflowX: "scroll"}}>
        {props.stations.map((station, key) => {
            const isSelected = !!props.currentStation
                && (props.currentStation.name === station.name);
            const onClick = () => isSelected ? props.setCurrentStation(null)
                : props.setCurrentStation(station);
            return <MapIconOption
                station={station}
                selected={isSelected}
                onClick={onClick}
                key={key}
            />
        }
        )}
    </Stack>
}
import { Container } from "@mui/material";
import NotesWidget from "./NotesWidget";
import { db } from "../instant";
import { tx } from "@instantdb/react";

interface Props {
    notes?: string
    pinId: string
}

export default function StationNotes(props: Props) {
    const setNotes = (notes: string) => {
        console.log(notes);
        db.transact(tx.mapPins[props.pinId].update({ notes }))
            .catch((err) => console.error("error updating notes: ", err));
    };

    return <Container
        maxWidth="sm"
        sx={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: "30px",
            alignItems: "center",
            height: "100%",
        }}
    >
        <NotesWidget value={props.notes || ""} update={setNotes} />
    </Container>
}
import { Stack, Typography } from "@mui/material";
import { StationType } from "../schema";
import { grey } from "@mui/material/colors";
import { getStationIconSrc } from "../utils";

interface Props {
    station: StationType
    selected: boolean
    onClick: () => void
}

export default function MapIconOption(props: Props) {
    const color = props.selected ? grey[400] : null;
    const iconSrc = getStationIconSrc(props.station);
    return <Stack
        direction="column"
        onClick={props.onClick}
        sx={{ alignItems: "center", padding: 2, borderRadius: 3, backgroundColor: color }}
    >
        <img style={{margin: "5px"}} alt={props.station.name} width="50px" height="50px" src={iconSrc} />
        <Typography align="center">{props.station.name}</Typography>
    </Stack >
}
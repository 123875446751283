import { Link, useMatch } from "react-router-dom"
import { BottomNavigation, BottomNavigationAction, Box, Paper } from "@mui/material";
import MapMaterialIcon from "@mui/icons-material/Map";
import ListIcon from "@mui/icons-material/List";
import DescriptionIcon from "@mui/icons-material/Description";
import EventInventory from "../components/EventInventory";
import EventNotes from "../components/EventNotes";
import { ViewOnlyContext } from "../context";
import DefaultLayout from "../layouts/DefaultLayout";
import EventMapHeader from "../components/EventMapHeader";
import EventMapContainer from "../components/EventMapContainer";

interface Props {
    viewOnly?: boolean
}

export default function EventScreen(props: Props) {
    const viewOnly = props.viewOnly || false;
    const routePrefix = viewOnly ? '/viewevent' : '/event';
    const routeTemplate = `/${routePrefix}/:eventId/:activeTab`;

    const routeMatch = useMatch(routeTemplate);
    if (!routeMatch) {
        return <p>ERROR: unknown route</p>
    }

    const { eventId, activeTab } = routeMatch.params;

    if (!eventId) {
        return <p>ERROR: event id</p>
    }

    if (!activeTab) {
        return <p>ERROR: activeTab</p>
    }

    const tabs = ["map", "inventory", "notes"];
    const activeTabId = tabs.indexOf(activeTab);

    return <ViewOnlyContext.Provider value={viewOnly}>
        <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
            <DefaultLayout>
                <Paper sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
                    <EventMapHeader eventId={eventId} />
                    <Box sx={{ flexGrow: 1 }} >
                        {activeTab === "map" && <EventMapContainer eventId={eventId} />}
                        {activeTab === "inventory" && <EventInventory eventId={eventId} />}
                        {activeTab === "notes" && <EventNotes eventId={eventId} />}
                    </Box>

                    <Box sx={{ height: "56px" }} />
                    <Paper elevation={3} style={{ position: "fixed", bottom: "0px", width: "100vw", height: "56px" }}>
                        <BottomNavigation showLabels value={activeTabId}>
                            <BottomNavigationAction label="Map" icon={<MapMaterialIcon />} component={Link} to={`${routePrefix}/${eventId}/map`} />
                            <BottomNavigationAction label="Inventory" icon={<ListIcon />} component={Link} to={`${routePrefix}/${eventId}/inventory`} />
                            <BottomNavigationAction label="Notes" icon={<DescriptionIcon />} component={Link} to={`${routePrefix}/${eventId}/notes`} />
                        </BottomNavigation>
                    </Paper>
                </Paper>
            </DefaultLayout>
        </Box>
    </ViewOnlyContext.Provider>
}
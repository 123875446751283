import L from "leaflet";
import { MapPin, StationType } from "../schema"
import { Marker } from "react-leaflet";
import { getStationIconSrc } from "../utils";
import StationMarkerPopup from "./StationMarkerPopup";

interface Props {
    pin: MapPin
    station: StationType
    position: L.LatLng
    onDelete: () => void
}

export default function StationMarker(props: Props) {
    const iconSrc = getStationIconSrc(props.station);
    const leafletIcon = L.icon({ iconUrl: iconSrc, iconSize: [50, 50] });

    return <Marker icon={leafletIcon} position={props.position}>
        <StationMarkerPopup
            pin={props.pin}
            station={props.station}
            iconSrc={iconSrc}
            onDelete={props.onDelete}
        />
    </Marker>
}
import { Card, CardContent, Typography } from "@mui/material";

interface Props {
    eventId?: string
}

export default function NoEventError(props: Props) {
    return <Card>
        <CardContent>
            <Typography variant="h3">Oops!</Typography>
            <Typography>Event '{props.eventId}' not found</Typography>
        </CardContent>
    </Card>
}
import { Alert, Snackbar } from "@mui/material"

export type DownloadStatus = 'Downloading' | 'Finished';

interface Props {
    status: DownloadStatus | null;
}

export default function DownloadNotifier(props: Props) {
    const { status } = props;

    const open = (status !== null);
    const isDownloading = (status === 'Downloading');
    const severity = isDownloading ? 'info' : 'success';

    const progressMessage = "Downloading maps for offline use...";
    const finishedMessage = "Finished downloading for offline use!";
    const message = isDownloading ? progressMessage : finishedMessage;

    return <Snackbar open={open}>
        <Alert severity={severity} variant="filled" sx={{ width: "100%" }}>
            {message}
        </Alert>
    </Snackbar >
}
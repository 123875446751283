import { Container } from "@mui/material";
import { useEvent } from "../context";
import NotesWidget from "./NotesWidget";
import { db } from "../instant";
import { tx } from "@instantdb/react";

interface Props {
    eventId: string;
}

export default function EventNotes(props: Props) {
    const event = useEvent(props.eventId);
    const notes = event?.notes || "";

    const setNotes = (notes: string) => {
        console.log(notes);
        db.transact(tx.events[props.eventId].update({ notes }))
            .catch((err) => console.error("error updating notes: ", err));
    };

    return <Container
        maxWidth="sm"
        sx={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: "30px",
            alignItems: "center",
            height: "100%",
        }}
    >
        <NotesWidget value={notes} update={setNotes} />
    </Container>
}
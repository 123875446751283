import { ErrorResponse, useRouteError } from "react-router-dom";

// TODO: use MUI card?
export default function ErrorScreen() {
  let error = useRouteError() as ErrorResponse;
  console.error(error);
  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Dang!</h1>
      <h3>{error.status}: {error.statusText}</h3>
      <p>{error.data}</p>
    </div>
  );
}

const styles: Record<string, React.CSSProperties> = {
  container: {
    margin: "auto",
    marginTop: "50px",
    padding: "10px",
    width: "50%",
    height: "50%",
    backgroundColor: "pink",
    border: "2px solid red",
    borderRadius: "10px",
  },
  header: {
    textAlign: "center",
  },
};
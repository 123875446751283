import { MuiFileInput } from 'mui-file-input'
import { FormGroup, Stack, Typography } from '@mui/material';
import { FieldProps } from 'formik';
import { useEffect, useState } from 'react';

import b64 from 'base64-js';

interface Props {
    label: string
}

export default function ImagePicker(props: FieldProps & Props) {
    const [file, setFile] = useState<File | null>(null);
    const [imageView, setImageView] = useState<JSX.Element>(<></>);

    useEffect(() => {
        if (file) {
            file.arrayBuffer().then((buf) => {
                const byteArray = new Uint8Array(buf);
                const encoded = b64.fromByteArray(byteArray)
                const dataUrl = `data:${file.type};base64,${encoded}`;
                const view = <img style={styles.image} alt="uploaded bg" src={dataUrl} />;
                setImageView(view);
            });

        }
    }, [file, setImageView])

    const { setFieldValue } = props.form;
    useEffect(() => {
        setFieldValue(props.field.name, file);
    }, [file, setFieldValue, props.field.name]);

    return <FormGroup>
        <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                <Typography width="150px">{props.label}</Typography>
                <MuiFileInput
                    value={file}
                    inputProps={{ accept: "image/*" }}
                    onChange={setFile}
                />
            </Stack>
            <Stack direction="column" spacing={1}>
                {imageView}
            </Stack>
        </Stack>
    </FormGroup>

}

const styles: Record<string, React.CSSProperties> = {
    image: {
        maxWidth: "200px",
        maxHeight: "200px",
        margin: "auto",
    }
};
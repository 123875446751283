import { Popup } from "react-leaflet";
import { useContext } from "react";
import { MapContext } from "../context";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { MapPin, StationType } from "../schema";
import StationNotes from "./StationNotes";
import CleanUpButton from "./CleanUpButton";

interface Props {
    pin: MapPin
    station: StationType
    iconSrc: string
    onDelete: () => void
}

export default function StationMarkerPopup(props: Props) {
    const { editMode } = useContext(MapContext);
    return <Popup>
        <Stack sx={{ alignItems: "center" }} direction="column" spacing={1} >
            <Stack sx={{ alignItems: "center" }} direction="row">
                {editMode && <Button
                    onClick={(ev) => {
                        // Delete the marker
                        props.onDelete()
                        // Prevent a click event on the map
                        // from accidentally creating a new marker
                        ev.stopPropagation();
                    }}
                    sx={{ width: "40px", minWidth: "40px", marginRight: 1 }}
                >
                    <DeleteIcon />
                </Button>}
                <Typography fontWeight="bold" style={{ margin: "5px", marginRight: "10px" }}>{props.station.name}</Typography>
                {editMode && <Box sx={{ width: "40px" }} />}
            </Stack>
            <Stack direction="row" sx={{ alignItems: "center" }}>
                <img style={{ marginRight: "10px" }} alt="icon" width="40px" height="40px" src={props.iconSrc} />
                <Typography sx={{margin: "2px"}}>{props.station.description}</Typography>
            </Stack>
            <CleanUpButton pin={props.pin}/>
            <Divider flexItem />
            <StationNotes pinId={props.pin.id} notes={props.pin.notes} />
        </Stack>
    </Popup>
}
import { Box, Stack, Typography } from "@mui/material";
import { useEvent } from "../context";
import NoEventError from "./NoEventError";

interface Props {
    eventId: string
}

export default function EventMapHeader(props: Props) {
    const event = useEvent(props.eventId);
    if (!event) {
        return <NoEventError eventId={props.eventId} />
    }

    return <Stack direction="row">
        <Box sx={{ width: "100%", overflowX: "auto", margin: "5px", padding: "5px" }} >
            <Typography variant="h3" sx={{ textAlign: "center", whiteSpace: "nowrap", margin: "auto" }}>{event.name}</Typography>
        </Box>
    </Stack>
}
import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { EventMap } from "../schema";
import DeleteMapDialog from "./DeleteMapDialog";
import { useState } from "react";

interface Props {
    eventName: string
    map: EventMap
    deleteable: boolean
}

export default function MapListSection(props: Props) {
    const { eventName, map, deleteable } = props;
    const [dialogOpen, setDialogOpen] = useState(false);
    const [mapToDelete, setMapToDelete] = useState<EventMap | undefined>(undefined);

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleClickDelete = (map: EventMap) => {
        setMapToDelete(map);
        setDialogOpen(true);
    }

    return <Box key={map.id} >
        <DeleteMapDialog
            eventName={eventName}
            map={mapToDelete}
            open={dialogOpen}
            onClose={handleCloseDialog}
        />

        <Divider />
        <br />

        <Stack
            direction="column"
            spacing={1}
            sx={{ position: "relative" }}
        >
            <Box sx={{ position: "relative" }}>
                <Typography
                    variant="h4"
                    sx={{ flex: 1, textAlign: "center" }}>
                    {map.name}
                </Typography>

                { // Don't allow the last map to be removed
                    deleteable && <IconButton
                        sx={{
                            position: "absolute",
                            top: 1,
                            right: 1,
                        }}
                        onClick={() => handleClickDelete(map)}
                    >
                        <DeleteIcon />
                    </IconButton>
                }
            </Box>
            <br />

            <img
                style={{
                    maxWidth: "200px",
                    maxHeight: "200px",
                    margin: "auto",
                }}
                alt="map bg"
                src={map.bgImageUrl}
            />
            <br />
        </Stack>
    </Box>



}
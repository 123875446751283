import { TransactionChunk, id, tx } from "@instantdb/core";
import { BACKEND_BASE_URL, IMAGE_CACHE_NAME } from "./constants";
import { getFileExtension, sha256Array } from "./utils";
import { db } from "./instant";
import { NavigateFunction } from "react-router";

export interface CreateMap {
    id: string
    name?: string
    bgImage: File | null
}

export const newEmptyMap = (): CreateMap => ({
    id: id(),
    name: "",
    bgImage: null,
});

export const bgImageKey = (index: number) => `maps[${index.toString()}].bgImage`;
export const mapNameKey = (index: number) => `maps[${index.toString()}].name`;

export const getImageUrl = async (file: File): Promise<string> => {
    // Get the filename
    const content = await file.arrayBuffer();
    const hashHex = await sha256Array(content);

    const fileExtension = getFileExtension(file.name);
    const fileName = `${hashHex}.${fileExtension}`;

    const imgUrl = `${BACKEND_BASE_URL}/images/${fileName}`;
    return imgUrl;
}

// Manually insert image response into service worker cache
// so that we can view the map before uploading while offline
const cacheImage = async (file: File, url: string, token: string): Promise<void> => {
    const cache = await window.caches.open(IMAGE_CACHE_NAME);
    const req = new Request(url, { headers: { token } });
    const res = new Response(file);
    await cache.put(req, res);
}

// Upload image to R2
const uploadImage = async (file: File, url: string, token: string): Promise<void> => {
    await fetch(
        url,
        {
            method: "PUT",
            body: file,
            headers: {
                "Content-Type": file.type,
                token
            },
        }
    );
}

export interface CreateMapsParams {
    maps: CreateMap[]
    eventId: string
    orderOffset?: number
    otherTxns: TransactionChunk[]
    token: string
    setIsUploading: (is: boolean) => void
    setSubmitting: (is: boolean) => void
    navigate: NavigateFunction
}

export async function createMaps(params: CreateMapsParams): Promise<void> {
    const uploadPromises: Promise<void>[] = [];

    const {
        maps,
        eventId,
        otherTxns,
        token,
        setIsUploading,
        setSubmitting,
        navigate,
    } = params;

    const orderOffset = params.orderOffset || 0;

    // Copy txns into new array
    const txs = [...otherTxns];

    // It's not ideal to await in a loop,
    // but these are not network requests,
    // just async browser operations.
    // So it's probably alright.
    for (let index = 0; index < maps.length; index++) {
        let map = maps[index];
        const mapName = map.name;
        const bgImage = map.bgImage as File;

        const imgUrl = await getImageUrl(bgImage);

        // Before uploading, cache image so that it can be viewed
        // even if we're currently offline.
        await cacheImage(bgImage, imgUrl, token);

        // Try to upload image.
        // service worker will retry later
        // if we're currently offline
        const uploadPromise = uploadImage(bgImage, imgUrl, token);
        uploadPromises.push(uploadPromise);

        // Append transaction
        txs.push(tx.maps[map.id].update({
            name: mapName,
            bgImageUrl: imgUrl,
            // keep maps in a consistent order
            order: index + orderOffset,
        }).link({ events: eventId }))
    }

    // Show loading spinner
    setIsUploading(true);

    // Update InstantDB
    await db.transact(txs);

    try {
        // Wait for uploads to complete before navigating
        await Promise.all(uploadPromises)
    } catch (err) {
        console.error('failed to upload image:', err);
        console.error('image upload will be retried later.');
    }

    setIsUploading(false);
    navigate(`/event/${eventId}`);
    setSubmitting(false);
}
import { CircularProgress, Dialog, DialogContent, DialogContentText, Stack } from "@mui/material";

interface Props {
    message: string
}

export default function LoadingIndicator(props: Props) {
    return <Dialog open={true}>
        <Stack
            direction="column"
            sx={{
                padding: "5px",
                display: "flex",
                alignItems: "center"
            }}
        >
            <CircularProgress />
            <DialogContent>
                <DialogContentText>{props.message}</DialogContentText>
            </DialogContent>
        </Stack>
    </Dialog>
}
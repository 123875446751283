import { useMatch } from 'react-router';
import EditMapListForm from '../components/EditMapListForm';
import DefaultLayout from '../layouts/DefaultLayout';

export default function EditMapListScreen() {
    const routeMatch = useMatch(`/event/:eventId/maplist`);
    if (!routeMatch) {
        return <p>ERROR: unknown route</p>
    }

    const { eventId } = routeMatch?.params;

    if (!eventId) {
        return <p>ERROR: event id</p>
    }

    return <DefaultLayout>
        <EditMapListForm eventId={eventId} />
    </DefaultLayout>
}
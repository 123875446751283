import EventsProvider from "../components/EventsProvider";
import Navbar from "../components/Navbar";
import NewVersionDialog from "../components/NewVersionDialog";

interface Props {
    children: JSX.Element | JSX.Element[]
}

export default function DefaultLayout(props: Props) {
    return <>
        <Navbar />
        <EventsProvider>
            {props.children}
        </EventsProvider>
        <NewVersionDialog />
    </>
}
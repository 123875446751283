export const LOGIN_ROUTE = "/login";
export const CREATE_EVENT_ROUTE = "/create-event";
export const EVENTS_LIST_ROUTE = "/events";
// home screen
export const DEFAULT_ROUTE = EVENTS_LIST_ROUTE;

export const IMAGE_CACHE_NAME = "images";
export const IMAGE_UPLOAD_QUEUE_NAME = "zw-image-upload";
export const IMAGE_UPLPOAD_RETRY_EVENT_TYPE = "replayQueue";

export const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL || "";

// instantdb id of latest version record
export const LATEST_VERSION_ID = "c233b98f-f205-4bca-94d2-d6b7fefa7b8b"

import HomeIcon from "@mui/icons-material/Home";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { DEFAULT_ROUTE } from "../constants";

export default function HomeButton() {
    return <Link to={DEFAULT_ROUTE}>
        <Button>
            <HomeIcon />
        </Button>
    </Link>
}
import { Typography } from "@mui/material";
import { EventsContext } from "../context";
import { db } from "../instant";
import CacheUpdater from "./CacheUpdater"
import LoadingIndicator from "./LoadingIndicator";

interface Props {
    children: JSX.Element | JSX.Element[]
}

export default function EventsProvider(props: Props) {
    const { data, isLoading, error } = db.useQuery({
        events: {
            maps: {
                mapPins: {
                    stationTypes: {}
                }
            },
        },
    });

    if (isLoading) {
        return <LoadingIndicator message="Loading event data..."/>;
    }

    if (error) {
        return <Typography>Oh no! {JSON.stringify(error)}</Typography>;
    }

    const eventsList = data?.events || [];
    const eventsMap = new Map();

    // Store events in map by id
    for (let event of eventsList) {
        // keep maps in a consistent order
        event.maps.sort((a, b) => a.order - b.order);
        eventsMap.set(event.id, event);
    }

    return <EventsContext.Provider value={{ events: eventsMap }}>
        {props.children}
        <CacheUpdater />
    </EventsContext.Provider>
}
import { Event, EventMap, MapPin, StationType } from "./schema";

export const getStationIconSrc = (station: StationType): string => {
    const pathname = `/icons/${station.iconName}`;

    const base = "http://example.com"; // Not actually used
    const url = new URL(pathname, base);

    // Bump version number to update icons (bust cache)
    // This is not the most elegant way to do this.
    const iconVersion = 2;
    url.searchParams.set('v', iconVersion.toString());

    // Combine everything after the domain name
    const relativeUrl = url.pathname + url.search;
    return relativeUrl;
}

export const sha256Text = async (s: string): Promise<string> => {
    const data = new TextEncoder().encode(s);
    return await sha256Array(data);
}

export const sha256Array = async (arr: ArrayBuffer): Promise<string> => {
    const hashBuffer = await crypto.subtle.digest("SHA-256", arr);
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    const hashHex = hashArray
        .map((b) => b.toString(16).padStart(2, "0"))
        .join(""); // convert bytes to hex string

    return hashHex;
}

export const getFileExtension = (fileName: string) => fileName.split(".").pop();

export const fetchImage = async (imageUrl: string): Promise<string> => {
    const resp = await fetch(
        imageUrl,
    );
    const imgBlob = await resp.blob();
    // Return image as data url string
    const objectUrl = URL.createObjectURL(imgBlob);
    return objectUrl
};

export const timeout = (ms: number): Promise<void> => {
    return new Promise<void>(resolve => {
        setTimeout(resolve, ms);
    })
}

export const getStationType = (pin: MapPin): StationType | undefined => {
    const { stationTypes } = pin;
    if (stationTypes && stationTypes.length === 1) {
        return stationTypes[0];
    } else {
        // fall back to old `kind` field for now
        // (or undefined if not present)
        return pin.kind
    }
}

export const getMap = (event: Event | undefined, mapNumber: number): EventMap | undefined => {
    const maps = event?.maps || [];
    return maps[mapNumber];
}
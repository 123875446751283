import { useContext } from "react";
import { FeatureGroup, useMapEvents } from "react-leaflet";
import L from "leaflet";
import { MapPin, StationType } from "../schema";
import StationMarker from "./StationMarker";
import { MapContext, useEventMap } from "../context";
import { db } from "../instant";
import { id, tx } from "@instantdb/react";
import { getStationType } from "../utils";

interface MarkerInfo {
    station: StationType
    position: L.LatLng
}

interface Props {
    bounds: L.LatLngBounds
    magnification: number
}

export default function StationMarkerGroup(props: Props) {
    const { eventId, mapNumber, cleanupMode, currentStation, setCurrentStation } = useContext(MapContext);
    const map = useEventMap(eventId, mapNumber);

    const overlayWidth = props.bounds.getEast();
    const overlayHeight = props.bounds.getNorth();

    const addPin = async (newMarker: MarkerInfo) => {
        if (!map) {
            throw new Error("no map");
        }

        const pinId = id();
        const mapPin: MapPin = {
            id: pinId,
            // Convert to fractional coordinates
            x: newMarker.position.lng / overlayWidth,
            y: newMarker.position.lat / overlayHeight,
            kind: newMarker.station,
            cleanedUp: false,
        };

        // Unselect the station. One marker per selection.
        setCurrentStation(null);

        // commit changes to database
        await db.transact(
            tx.mapPins[pinId]
                .update(mapPin)
                .link({ maps: map.id })
                .link({ stationTypes: newMarker.station.id }),
        );
        console.log('saved!');
    }

    const deletePin = async (pinId: string) => {
        await db.transact(tx.mapPins[pinId].delete())
    }

    useMapEvents({
        click: async ev => {
            if (!currentStation) {
                // Do nothing if no station type is selected
                return;
            }

            const newPosition = ev.latlng;

            // Make sure the click is within the map bounds
            if (props.bounds.contains(newPosition)) {
                const newMarker: MarkerInfo = {
                    station: currentStation,
                    position: newPosition,
                };
                await addPin(newMarker)
            }
        }
    })

    let mapPins = map?.mapPins || [];
    if (cleanupMode) {
        mapPins = mapPins.filter(
            // Hide cleaned up stations
            pin => !pin.cleanedUp
        )
    }

    console.log('PINS: ', mapPins);

    return <FeatureGroup>
        {mapPins.map(pin => ({
            pin,
            station: getStationType(pin),
        }))
            .filter(({ station }) => station)
            .map(({ pin, station }) => {
                // transform lat, lng into fractional coords
                // note order: lat, lng = y, x
                const position = new L.LatLng(
                    overlayHeight * pin.y,
                    overlayWidth * pin.x
                );

                const marker: MarkerInfo = {
                    station: station as StationType,
                    position
                };

                return <StationMarker
                    pin={pin}
                    station={marker.station}
                    position={marker.position}
                    key={pin.id}
                    onDelete={() => deletePin(pin.id).catch(console.error)}
                />
            })}
    </FeatureGroup>
}
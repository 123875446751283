import AddIcon from "@mui/icons-material/Add";
import { Fab } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CREATE_EVENT_ROUTE } from "../constants";

export default function CreateEventButton() {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(CREATE_EVENT_ROUTE);
    }

    return <Fab sx={{
        position: 'fixed',
        bottom: 16,
        right: 16,
    }} onClick={handleClick}>
        <AddIcon />
    </Fab>
}
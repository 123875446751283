import { TextField } from "@mui/material";
import { FieldProps } from "formik";

interface Props {
}

export default function FormTextInput(props: FieldProps & Props) {
    const fieldName = props.field.name;
    return <TextField
        fullWidth
        id={fieldName}
        name={fieldName}
        value={props.field.value}
        onChange={props.field.onChange}
        onBlur={props.field.onBlur}
        error={props.form.touched[fieldName] && Boolean(props.form.errors[fieldName])}
        helperText={(props.form.touched[fieldName] && props.form.errors[fieldName]) as string}
        {...props}
    />
}